<template>
  <div class="about">
    <a-card style="margin-bottom:20px">
      <a-form layout="inline" :form="form">
        <a-form-item label="综合搜索（姓名/账号）:">
          <a-input v-model="form.name" placeholder="请输入姓名/账号"/>
        </a-form-item>
        <a-form-item label="激活状态:">
          <a-select style="width: 120px" v-model="form.status">
            <a-select-option value="all">
              全部
            </a-select-option>
            <a-select-option value="1">
              已激活
            </a-select-option>
            <a-select-option value="0">
              未激活
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="dep" label="部门:">
          <a-tree-select
            v-model="arr"
            style="width: 200px"
            :tree-data="treeData"
            tree-checkable
            :show-checked-strategy="SHOW_PARENT"
            search-placeholder="Please select"
            :replaceFields="{children:'child',title:'name',key:'id',value:'id'}"
            @change="getSeleted"
          />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" icon="search" @click="handleClickSearch">
            搜索
          </a-button>    
        </a-form-item>
        <a-form-item>
          <a-button icon="reload" @click="handleReset">
            重置
          </a-button>    
        </a-form-item>
      </a-form>
    </a-card>
    <a-card>
      <a-row style="margin-bottom:10px;">
        <a-col :span="12" class="title">
          <span class="table-title">企业成员</span>
          仅显示获取了配置客户联系功能的成员
        </a-col>
        <a-col class="btn-view" :span="12">
          <!-- <a-button icon="reload" @click="handleReload">刷新</a-button> -->
          <a-button class="invite-btn" type="primary" @click="handleInvite" :disabled="selectdList.length<=0">托管邀请</a-button>
          <a-button type="primary" @click="handleSync" :disabled="syncBtn">同步</a-button>
        </a-col>
      </a-row>
      <a-table
          :columns="columns"
          :data-source="tableData"
          :row-key="(record) => record.userid"
          :pagination="pagination"
          :loading="loading"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange ,getCheckboxProps:getCheckboxProps}"
          @change="handleTableChange"
        >
        <div class="account-view" slot="account" slot-scope="name,record">
          <a-avatar :src="record.thumb_avatar" />
          <div class="text">
            <div>姓名:{{record.name}}</div>
            <div>账号:{{record.userid}}</div>
          </div>
          
        </div>
        <span slot="mobile" slot-scope="text">{{formatPhone(text)}}</span>
        <a-tag slot="status" slot-scope="text" :color="text===0?'blue':'green'">{{text===0?'未激活':'已激活'}}</a-tag>
        <div slot="time" slot-scope="text">
          {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
        </div>
      </a-table>
    </a-card>
    <a-modal 
      v-model="visible"  
      :title="`已选择成员(${selectdList.length})`"
      width="60%"
      :bodyStyle="{height:'500px',overflow:'scroll'}"
    >
      <a-list
        class="demo-loadmore-list"
        item-layout="horizontal"
        :data-source="selectdList"
      >
        <a-list-item slot="renderItem" slot-scope="item,index">
          <div slot="actions" @click="handleDelete(item,index)">删除</div>
            <a-list-item-meta >
            <div slot="title">{{ item.name }}</div>
            <div slot="description">{{item.userid}}</div>
            <a-avatar
              slot="avatar"
              :src="item.thumb_avatar"
            />
          </a-list-item-meta>
          <!-- <div>content</div> -->
        </a-list-item>
      </a-list>
      <div slot="footer" class="modal-foot">
        <a-button type="primary" @click="confirmSend">确定发送邀请</a-button>
        <a-button @click="visible = false">取消</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { TreeSelect } from 'ant-design-vue';
import * as Utils from '../utils/utils.js'
import {
    mapState
  } from 'vuex'
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const columns = [
   {
      title: "姓名/账号",
      dataIndex: "account",
      ellipsis: true,
      scopedSlots: { customRender: 'account' },
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'mobile' },
    },
    {
      title: "激活状态",
      dataIndex: "status",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'status' },
    },
    {
      title: "最后同步时间",
      dataIndex: "sync_time",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'time' },
      sorter:(a,b)=>a.sync_time - b.sync_time
    }
]

export default {
  name:'Invite',
  data(){
    return {
      form:{
        name:"",
        status:'all',
        dept_id:[]
      },
      treeData:[],
      SHOW_PARENT,
      columns,
      tableData:[],
      loading:false,
      visible:false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["5","10", "20"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      selectdList:[],
      selectedRowKeys:[],
      arr:[],
      syncBtn:false
    }
  },
  computed: {
    ...mapState(['mqttInit'])
  },
  watch:{
    mqttInit:{
      handler(val){
        if(val){
          this.getDept()
          this.handleSearch()
        }
      },
      immediate:true
    }
  },
  methods:{
    onSelectChange(selectedRowKeys,selectedRows){
      // console.log(selectedRowKeys.length,selectedRows.length)
      this.selectedRowKeys = selectedRowKeys;
      let empty = this.selectdList.concat(selectedRows)
      let obj = {}
      empty = empty.reduce((cur,next)=>{
        obj[next.userid] ? "" : obj[next.userid] = true && cur.push(next);
        return cur;
      },[])
      
     empty = empty.filter(item=>{
        return this.selectedRowKeys.includes(item.userid)
      })
      this.selectdList = empty
      
    },
    getCheckboxProps(record){
       return{props: {
            disabled: record.status === 1, // Column configuration not to be checked
            status: record.status,
          }}
    },
    getSeleted(val,label,e){
      this.form.dept_id = []
      this.getCheckedVal(e.allCheckedNodes||[])
    },
    getCheckedVal(checkedList){
      checkedList.forEach(item=>{
        if(item.children){
          this.getCheckedVal(item.children)
        }
        this.form.dept_id.push(item.node.data.props.id)
      })
    },
    //点击查询
    handleClickSearch(){
      this.selectedRowKeys = []
      this.selectdList = []
      this.pagination.current = 1
      this.handleSearch()
    },
    //重置
    handleReset(){
      this.form = {
         name:"",
         status:'all',
         dept_id:[]
      }
      this.arr = []
    },
    //获取部门信息
    getDept(){
      if(!this.mqttInit){
        this.$message.info('连接中...')
        return
      }
      this.loading = true
      let payload = {
        msgId:'listDept',
        data: {},
      }
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/wxDept/listDept",
          payload,
        },
        (topic, data) => {
          this.loading = false;
          this.treeData = data.data.department;
        },
        this
      );
    },
    handleDelete(item,index){
      this.selectdList.splice(index,1)
      this.selectedRowKeys.splice(index,1)
    },
    //点击托管邀请
    handleInvite(){
      let payload = {
        msgId:"searchRobots",
        data: {},
        };

        this.$mqtt.doPublish(
            {
                pubTopic: `scrm/weUser/searchRobots`,
                payload,
            },
            (topic, data) => {
            if(data.code===200){
                if(data.data.notcreatedCount>=this.selectedRowKeys.length){
                    this.visible = true
                }else{
                  this.$message.error(`当前允许发送邀请成员为“${data.data.notcreatedCount}”名，请调整选择成员人数`)
                }
            }
            
            },
            this
        );
      
    },
    //确定发送邀请
    confirmSend(){
      if(this.selectdList.length===0){
        this.$message.warning("请先选择成员")
        return
      }
      this.selectdList.forEach((item)=>{
        this.sendInvite(item.userid)
      })
      setTimeout(()=>{
        this.handleClickSearch()
      },3000)
    },
    //发送邀请
    sendInvite(id){
      let payload = {
        clientId:id,
        msgId:'cardmsg',
        data: {
          touser:id
        },
      };
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/thirdAppManage/sendCardMsg",
          payload,
        },
        (topic,data) => {
          // console.log(data)
          if(data.code===200){
            this.$message.success('发送成功')
            this.selectedRowKeys = []
            this.selectdList = []
            this.visible = false
          }else{
            this.$message.warning('发送失败')
          }          
        },
        this
      );
    },
    //查询
    handleSearch(){
      if(!this.mqttInit){
        this.$message.info('连接中...')
        return
      }
      this.loading = true
      let param = {}
      if(this.form.name){
        param.name = this.form.name
      }
      if(this.form.status!=="" && this.form.status!=="all"){
        param.status = this.form.status
      }
      if(this.form.dept_id.length>0){
        param.dept_id = this.form.dept_id
      }
      let payload = {
        msgId:'wxuserlist',
        data: {
          pageSize:this.pagination.pageSize,
          pageNum:this.pagination.current,
          ...param
        },
      };

      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/weUser/searchWxUserByPage",
          payload,
        },
        (topic, data) => {
          if(data.code===200){
            this.loading = false;
            this.tableData = data.data.res;
            this.pagination.total = data.data.count;
          }
          
        },
        this
      );
    },
    handleChange(val){
      this.form.status = val
    },
    //刷新
    handleReload(){
      this.handleSearch()
    },
  //同步
    handleSync(){
      this.loading = false
      this.syncBtn = true
      setTimeout(()=>{
        this.handleClickSearch()
      },5000)
      setTimeout(()=>{
        this.syncBtn = false
      },10000)
      let payload = {
        data:{}
      }
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/weUser/syncWxUsers",
          payload,
        },
        (topic,data) => {
          // console.log(data)
        },
        this
      );
    },
    //table分页改变
    handleTableChange(pagination){
      this.pagination = pagination
      this.handleSearch()
    },
    formatPhone(tel){
      return tel ? Utils.formatPhone(tel) : ''
    }
  }
}
</script>
<style lang="scss" >
.modal-foot{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.about{
  background-color:#F2F2F2 ;
  .title{
    color: #666;
    font-size: 14px;
    .table-title{
      color: #333;
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
    }
  }
  .btn-view{
    display: flex;
    justify-content: flex-end;
    .invite-btn{
      margin:0px 20px;
    }
  }
  
}
.account-view{
  display: flex;
  align-items: center;
  .text{
    margin-left: 5px;
  }
  // justify-content: center;
}
</style>